<template>
  <div>
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <v-row
          class="pt-0 pb-0"
          style="height: 100vh"
        >
          <v-col
            class="pt-0 pb-0 pr-0"
            cols="12"
            md="4"
            style="border-right: 1px solid #ddd;"
          >
            <v-card
              tile
              flat
              max-height="100vh"
              class="overflow-y-auto"
            >
              <ActionList
                v-if="tab.key === 'active'"
                :list-items="locations"
                :load-items-callback="getLocations"
                :fixed-filters="activeLocationsFilters"
                :empty-text="$t('noLocationsFound')"
              >
                <template v-slot:item="{ item, index }">
                  <LocationListItem
                    :location="item"
                    :key="item.uuid"
                    v-on="$listeners"
                    @click="selectLocation(item)"
                  />
                  <v-divider
                    inset
                    :key="index"
                  />
                </template>
              </ActionList>
              <ActionList
                v-if="tab.key === 'inactive'"
                :list-items="locations"
                :load-items-callback="getLocations"
                :fixed-filters="inactiveLocationsFilters"
                :empty-text="$t('noLocationsFound')"
              >
                <template v-slot:item="{ item, index }">
                  <LocationListItem
                    :location="item"
                    :key="item.uuid"
                    v-on="$listeners"
                    @click="selectLocation(item)"
                  />
                  <v-divider
                    inset
                    :key="index"
                  />
                </template>
              </ActionList>
            </v-card>
          </v-col>
          <v-col
            cols="8"
            v-if="!$vuetify.breakpoint.xsOnly"
            class="grey lighten-3"
          >
            <v-card
              tile
              flat
              max-height="100vh"
              class="overflow-y-auto grey lighten-3"
            >
              <LocationProfile
                v-if="selectedLocation"
                class="mb-4 mr-2"
                :location="selectedLocation"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <v-bottom-sheet
      v-if="$vuetify.breakpoint.xsOnly && selectedLocation"
      v-model="showBottomSheet"
    >
      <LocationProfile
        v-if="selectedLocation"
        class="grey lighten-3 mb-3 overflow-y-auto"
        :location="selectedLocation"
        style="max-height: 80vh"
      />
    </v-bottom-sheet>
    <v-dialog
      v-if="createNewLocation"
      v-model="createNewLocation"
      persistent
      scrollable
      max-width="800"
    >
      <v-card>
        <v-card-title>
          {{ $t('createLocation') }}
        </v-card-title>
        <v-card-text class="grey lighten-3 py-5">
          <LocationInformation
            :location="newLocation"
            @updateLocationValue="updateLocationValue"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            large
            text
            color="error"
            @click="cancelCreateNew"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            large
            color="primary"
            @click="createNew"
            :loading="creatingNew"
            :disabled="creatingNew || disabled"
          >
            {{ $t('create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import ActionList from "@/components/common/list/ActionList"
  import LocationListItem from "@/components/location/LocationListItem"
  import LocationProfile from "@/components/location/LocationProfile"
  import LocationInformation from "@/components/LocationInformation"

  export default {
    name: 'Locations',
    components: {
      LocationInformation,
      ActionList,
      LocationListItem,
      LocationProfile
    },
    data: () => ({
      createNewLocation: false,
      newLocation: null,
      creatingNew: false
    }),
    computed: {
      disabled() {
        return !this.newLocation || !this.newLocation.label || !this.newLocation.description
      },
      showBottomSheet: {
        get() {
          return this.selectedLocation !== null
        },
        set(value) {
          this.selectedLocation = null
        }
		  },
      activeLocationsFilters() {
        return [
          {
            key: 'active',
            value: true,
            operator: 'equals',
            type: 'boolean'
          }
        ]
      },
      inactiveLocationsFilters() {
        return [
          {
            key: 'active',
            value: false,
            operator: 'equals',
            type: 'boolean'
          }
        ]
      },
      selectedLocation: {
        get() {
          return this.$store.state.selectedLocationProfile
        },
        set(value) {
          this.$store.commit('updateSelectedLocationProfile', value)
        }
      },
      locations() {
        return this.$store.state.locations
      },
      activeLocationsCount: function() {
        return this.activeLocations !== null ? this.activeLocations.length : 0
      },
      inactiveLocationsCount: function() {
        return this.inactiveLocations !== null ? this.inactiveLocations.length : 0
      },
      activeLocations: function() {
        if(this.locations === null || this.locations === undefined) {
          return null
        }

        return this.locations.filter(location => {
          return !!location.active
        })
      },
      inactiveLocations() {
        if(this.locations === null || this.locations === undefined) {
          return null
        }

        return this.locations.filter(location => {
          return !location.active
        })
      },
      tabs() {
        return [
          {
            title: this.$t('active'),
            key: 'active',
            badge: {
              color: 'green',
              content: this.activeLocationsCount
            }
          },
          {
            title: this.$t('inactive'),
            key: 'inactive',
            badge: {
              color: 'red',
              content: this.inactiveLocationsCount
            }
          }
        ]
      },
      selectedAppBarTab: function() {
        return this.$store.state.selectedAppBarTab
      },
    },
    methods: {
      createNew() {
        this.creatingNew = true
        this.$store.dispatch('createLocation', { location: this.newLocation }).then(result => {
          if(result) {
            this.newLocation = null
            this.createNewLocation = false
            this.$store.commit('updateActionSuccess', {
              message: this.$t('locationCreated')
            })
          } else {
            this.$store.commit('updateActionError', {
              message: this.$t('couldNotCreateLocation')
            })
          }
        }).catch(() => {
          this.$store.commit('updateActionError', {
            message: this.$t('couldNotCreateLocation')
          })
        }).finally(() => {
          this.creatingNew = false
        })
      },
      cancelCreateNew() {
        this.createNewLocation = false
        this.newLocation = null
      },
      selectLocation(location) {
        this.selectedLocation = location
      },
      getLocations() {
        return this.$store.dispatch('getLocations')
      },
      addLocation() {
        this.newLocation = {
          label: null,
          description: null,
          phone: null,
          email: null,
          active: true,
          address: {
            country: null,
            name_line: null,
            thoroughfare: null,
            postal_code: null,
            locality: null
          },
          gps: {
            lat: null,
            lon: null
          },
          opening_rule: null,
          opening_hours: [],
          bookableHours: [],
          excludedLocationVariationsFromBookings: null,
          excludedZonesFromBookings: null,
          excludedZonesFromOnlineBookings: null,
          maximumSalesPerSlot: null,
          // maxDeliveriesPerSlot: null, // is not handled in location mutation yet
          intervalBetweenSlotsInMillis: null,
          pauseBetweenSlotsInMillis: null,
          showWaitTime: false
        }
        this.createNewLocation = true
        // window.top.location.href = 'https://admin.salescloud.is/store/locations/add'
      },
      exportLocations() {
        this.$store.commit('updateExportObjects', this.locations)
      },
      updateLocationValue({ key, value }) {
        this.newLocation[key] = value
      },
    },
    beforeRouteLeave(to, from, next) {
      this.selectedLocation = null
      next()
    },
    created() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      this.$store.commit('updateAppBarFab', {
        color: 'pink',
        callback: this.addLocation
      })
      this.$store.commit('updateAppBarExport', {
        color: 'pink',
        callback: this.exportLocations
      })
      this.$store.dispatch('getEtags')
    },
  }
</script>
